<template>
    <div class="main-container" dir="ltr">
        <div class="row mb-4">
            <div  class="col-md-12 my-auto">
                <h5 style="text-align: right;margin-top: 15px;">كل الكورسات </h5>
            </div>
        </div>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>                        
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in AllCourses" :key="publish.id">
                <div class="video-item-card">
                    <a @click="GoToSeriesContent(publish)">
                        <div class="video-thumb">
                            <img :src="publish.img" class="custom-logo" alt="Sayara">
                        </div>
                    </a>
                    <div class="video-content">
                        <div class="d-flex">
                            <div class="my-auto ">
                                <a @click="GoToSeriesContent(publish)">
                                    <h5>{{publish.name_ar}}</h5>
                                </a>
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a class="author " @click="GoToSeriesContent(publish)">
                                            {{publish.cp_name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
 name:'AppCourses',
 async setup() {
    const router = useRouter();
    const cookie = useCookie();
    // const toast = useToast();
    const AllCourses = ref([]);
    try {
      await HTTP.get(`GetAllCourses.php`).then((res) => {
        AllCourses.value = res.data.Courses; 
      });
    } catch (err) {
      console.log(err);
    }
    const GoToSeriesContent = (publish) => {
        if(cookie.isCookieAvailable("phone") ) {
          router.push({ name: "SeriesContent", params: { series_id: publish.id } });
        } else {
            window.open("http://courseatk.net/MTN_LandingPage_APIs/subscribe.php?portalId=1");
        }
    };

    return { AllCourses ,GoToSeriesContent };
    
  },

}
</script>

<style>

</style>